import React, { useRef } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

import { Navbar } from 'shared/layouts/LandingLayout/components/Navbar';
import { Content } from 'shared/layouts/LandingLayout/components/Content';

import { Footer } from '../../ui/Footer';

export const LandingLayout: React.FC = () => {
    const aboutRef = useRef(null);
    const skillsRef = useRef(null);
    const faqRef = useRef(null);
    const demoRef = useRef(null);
    const createStoreRef = useRef(null);

    const contactsRef = useRef(null);

    return (
        <StyledLayout>
            <Navbar
                aboutRef={aboutRef}
                skillsRef={skillsRef}
                faqRef={faqRef}
                demoRef={demoRef}
                createStoreRef={createStoreRef}
                contactsRef={contactsRef}
            />
            <Content
                aboutRef={aboutRef}
                skillsRef={skillsRef}
                faqRef={faqRef}
                demoRef={demoRef}
                createStoreRef={createStoreRef}
            />
            <Footer contactsRef={contactsRef} />
        </StyledLayout>
    );
};

const StyledLayout = styled(Layout)`
    background: #ffffff;
`;
